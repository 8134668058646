// import React, { useState } from "react";
// import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
// import { faSearchPlus, faSearchMinus } from "@fortawesome/free-solid-svg-icons";
// import "../style/Mybook.css";

// const Zoom = () => {
//   const [zoomLevel, setZoomLevel] = useState(1);

//   const handleZoomIn = () => {
//     setZoomLevel((prevZoomLevel) => Math.min(prevZoomLevel + 0.5, 2));
//   };

//   const handleZoomOut = () => {
//     setZoomLevel((prevZoomLevel) => Math.max(prevZoomLevel - 0.5, 0.1));
//   };

//   // Define style for the zoomed container with transition
//   const zoomedContainerStyle = {
//     transform: `scale(${zoomLevel})`,
//     transition: "transform 0.5s ease-in-out",
//   };

//   return (
//     <div className="zoom-container" style={zoomedContainerStyle}>
//       <div className="zoom-buttons">
//         <button onClick={handleZoomIn}>
//           <FontAwesomeIcon icon={faSearchPlus} />
//         </button>
//         <button onClick={handleZoomOut}>
//           <FontAwesomeIcon icon={faSearchMinus} />
//         </button>
//       </div>
//     </div>
//   );
// };

// export default Zoom;

// Zoom.js
import React, { useState } from "react";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faSearchPlus, faSearchMinus } from "@fortawesome/free-solid-svg-icons";

import "../style/Mybook.css";

const Zoom = ({ zoomLevel, setZoomLevel }) => {
  const handleZoomIn = () => {
    console.log("zoomin");
    console.log(zoomLevel);
    setZoomLevel((prevZoomLevel) => Math.min(prevZoomLevel + 0.5, 5));
  };

  const handleZoomOut = () => {
    console.log("zoomout");
    console.log(zoomLevel);
    setZoomLevel((prevZoomLevel) => Math.max(prevZoomLevel - 0.5, 0.5));
  };

  return (
    <div className="zoom-buttons">
      <button onClick={handleZoomIn}>
        <FontAwesomeIcon icon={faSearchPlus} />
      </button>
      <button onClick={handleZoomOut}>
        <FontAwesomeIcon icon={faSearchMinus} />
      </button>
    </div>
  );
};

export default Zoom;
