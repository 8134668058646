import React, { useState } from "react";

const SocialMedia = () => {
  const [showShareOptions, setShowShareOptions] = useState(false);

  const shareViaEmail = () => {
    const url = window.location.href;
    window.location.href = `mailto:?subject=Check%20out%20this%20flipbook&body=${encodeURIComponent(
      url
    )}`;
  };

  const shareOnWhatsApp = () => {
    const url = window.location.href;
    window.open(`whatsapp://send?text=${encodeURIComponent(url)}`);
  };

  const copyLinkToClipboard = () => {
    const url = window.location.href;
    navigator.clipboard
      .writeText(url)
      .then(() => {
        alert("Link copied to clipboard!");
      })
      .catch((error) => {
        console.error("Failed to copy:", error);
      });
  };

  const shareOnFacebook = () => {
    const url = window.location.href;
    window.open(
      `https://www.facebook.com/sharer/sharer.php?u=${encodeURIComponent(url)}`,
      "_blank"
    );
  };

  const toggleShareOptions = () => {
    setShowShareOptions(!showShareOptions);
  };

  return (
    <div className="social-media-share" style={{ alignContent: "left" }}>
      <button
        className="share-button rounded-circle"
        onClick={toggleShareOptions}
      >
        <i className="fas fa-share"></i>
      </button>
      {showShareOptions && (
        <div className="share-options">
          <button onClick={shareViaEmail}>
            <i className="fas fa-envelope"></i>
          </button>
          <button onClick={shareOnFacebook}>
            <i className="fab fa-facebook"></i>
          </button>
          <button onClick={shareOnWhatsApp}>
            <i className="fab fa-whatsapp"></i>
          </button>
          <button onClick={copyLinkToClipboard}>
            <i className="fas fa-copy"></i>
          </button>
        </div>
      )}
    </div>
  );
};

export default SocialMedia;
