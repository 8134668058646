import React, { useEffect, useState } from "react";
import "./App.css";
import MyBook from "./components/modules/Mybook"; // ensure the import path is correct
import flipbookData from "../src/components/data/innerWheel.json"; // move JSON files to src/data
import flipbookData01 from "../src/components/data/innerWheel01.json";
import flipbookData02 from "../src/components/data/innerWheel02.json";
import amoghrmc from "../src/components/data/amoghrmc.json";

import { BrowserRouter as Router, Routes, Route } from "react-router-dom";
import GoogleAds from "./components/modules/GoogleAds";

const App = () => {
  const fullflipbookData = flipbookData.concat(flipbookData01, flipbookData02);

  return (
    <div className="app">
      <GoogleAds />
      <Router>
        <Routes>
          <Route
            path="/innerWheel"
            element={<MyBook pages={fullflipbookData} />}
          />
          <Route path="/amoghrmc" element={<MyBook pages={amoghrmc} />} />
        </Routes>
      </Router>
    </div>
  );
};

export default App;
