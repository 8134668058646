import React from "react";
import html2canvas from "html2canvas";
import jsPDF from "jspdf";

const DownloadPdf = ({ pages }) => {
  const downloadPdf = () => {
    const pagesToRender = [];

    for (let i = 0; i < pages.length; i++) {
      const pageElement = document.getElementById(`page-${i}`);
      if (pageElement) {
        pagesToRender.push(html2canvas(pageElement));
      } else {
        console.error(`Page ${i} element not found.`);
      }
    }

    Promise.all(pagesToRender).then((canvasPages) => {
      const pdf = new jsPDF();
      canvasPages.forEach((canvas, index) => {
        if (index !== 0) {
          pdf.addPage();
        }
        pdf.addImage(
          canvas,
          "JPEG",
          0,
          0,
          pdf.internal.pageSize.width,
          pdf.internal.pageSize.height
        );
      });

      pdf.save("flipbook.pdf");
    });
  };

  return (
    <button className="download-pdf-button" onClick={downloadPdf}>
      Download PDF
    </button>
  );
};

export default DownloadPdf;
