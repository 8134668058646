import React, { useState, useEffect, useRef } from "react";
import HTMLFlipBook from "react-pageflip";
import "../modules/Analytics.js";
import "../style/Mybook.css";
import Zoom from "./Zoom";
import DownloadPdf from "./DownloadPdf";
import "@fortawesome/fontawesome-free/css/all.css";
import SocialMedia from "./SocialMedia";
import GoogleAds from "./GoogleAds";

const PageCover = React.forwardRef(({ imageSource, children }, ref) => {
  return (
    <div className="cover" ref={ref} data-density="hard">
      <img
        src={imageSource}
        alt="Cover"
        style={{ width: "100%", height: "100%" }}
      />
      <div>
        <h2>{children}</h2>
      </div>
    </div>
  );
});

const Page = React.forwardRef((props, ref) => {
  const { backgroundImage } = props;
  const pageStyle = {
    backgroundImage: backgroundImage ? `url(${backgroundImage})` : "none",
    width: "100%",
    height: "100%",
  };

  return (
    <div className="page" ref={ref} style={pageStyle}>
      <div className="page-content">
        <div className="logo-container">
          <img className="logo" src="/images/innerwheel/logo.png" alt="Logo" />
        </div>
        <div
          className={`${
            props.subbgimg
              ? props.subbgimg + " " + "page-main-content"
              : "page-main-content"
          }`}
        >
          {props.children}
        </div>
      </div>
      <div className="page-footer bg-primary">
        {props.number > 0 && (
          <div className="page-number-container rounded-circle bg-light text-dark">
            <p className="page-number">{props.number}</p>
          </div>
        )}
      </div>
    </div>
  );
});

function MyBook(props) {
  const [pages, setPages] = useState([]);
  const pageFlipRef = useRef(null);
  const [zoomLevel, setZoomLevel] = useState(1);
  const [showAds, setShowAds] = useState(false); // Define showAds state

  useEffect(() => {
    setPages(props.pages);

    const handleKeyDown = (event) => {
      switch (event.keyCode) {
        case 37: // Left arrow key
          if (pageFlipRef.current && pageFlipRef.current.pageFlip()) {
            pageFlipRef.current.pageFlip().flipPrev();
          }
          break;
        case 39: // Right arrow key
          if (pageFlipRef.current && pageFlipRef.current.pageFlip()) {
            pageFlipRef.current.pageFlip().flipNext();
          }
          break;
        default:
          break;
      }
    };

    const handleHashChange = () => {
      scrollToPageFromHash();
    };

    window.addEventListener("keydown", handleKeyDown);
    window.addEventListener("hashchange", handleHashChange);

    const checkFlipBookInstance = () => {
      if (pageFlipRef.current && pageFlipRef.current.pageFlip()) {
        scrollToPageFromHash();
      } else {
        requestAnimationFrame(checkFlipBookInstance);
      }
    };

    checkFlipBookInstance();

    return () => {
      window.removeEventListener("keydown", handleKeyDown);
      window.removeEventListener("hashchange", handleHashChange);
    };
  }, [props.pages]);

  useEffect(() => {
    // Fetch showAds from config.json
    fetch("/config.json")
      .then((response) => response.json())
      .then((config) => {
        setShowAds(config.showAds);
      })
      .catch((error) => {
        console.error("Failed to load the configuration:", error);
      });
  }, []);

  const scrollToPageFromHash = () => {
    const hash = window.location.hash;
    if (hash && hash.includes("page-")) {
      const pageNumber = parseInt(hash.substring(hash.indexOf("-") + 1));
      if (
        !isNaN(pageNumber) &&
        pageFlipRef.current &&
        pageFlipRef.current.pageFlip()
      ) {
        pageFlipRef.current.pageFlip().turnToPage(pageNumber);
      }
    }
  };

  const handlePageLinkClick = (pageNumber) => (event) => {
    event.preventDefault();
    if (pageFlipRef.current && pageFlipRef.current.pageFlip()) {
      pageFlipRef.current.pageFlip().turnToPage(pageNumber);
      window.location.hash = `page-${pageNumber}`;
    } else {
      console.error("pageFlip instance is not available.");
    }
  };

  const handleFlip = (event) => {
    const currentPageIndex = event.data;
    console.log(`Flipped to page index: ${currentPageIndex}`);
    window.location.hash = `page-${currentPageIndex}`;
  };

  return (
    <div className="bg-container">
      <div className="happy-logo-top-left">
        <a
          href="https://happynenapu.com"
          target="_blank"
          rel="noopener noreferrer"
        >
          <img
            className="happy-logo"
            src="/images/innerwheel/happynenapulogo.png"
            alt="Happy-Logo"
          />
        </a>
      </div>
      <div className="album-web">
        <Zoom zoomLevel={zoomLevel} setZoomLevel={setZoomLevel} />
        <HTMLFlipBook
          width={450}
          height={585}
          minWidth={315}
          maxWidth={1000}
          minHeight={420}
          maxHeight={1350}
          showCover={true}
          startZIndex={1}
          flippingTime={1000}
          style={{
            margin: "0 auto",
            transform: `scale(${zoomLevel})`,
            transition: "transform 0.5s ease-in-out",
          }}
          maxShadowOpacity={0.5}
          maxOpacity={10}
          ref={pageFlipRef}
          onFlip={handleFlip}
        >
          <PageCover imageSource="/images/innerwheel/cover.jpg"></PageCover>
          {props.pages.map((page, index) => {
            const Component = React.lazy(() => import(`./${page.component}`));
            return (
              <Page
                number={page.pageNumber}
                subbgimg={page.subbgimg}
                backgroundImage={page.backgroundImage}
                key={index}
              >
                <div id={`page-${index}`}>
                  <React.Suspense fallback={<div>Loading...</div>}>
                    <Component {...page.data} />
                  </React.Suspense>
                </div>
              </Page>
            );
          })}
          <PageCover imageSource="/images/innerwheel/lastcover.png"></PageCover>
        </HTMLFlipBook>
        <div className="sidebar">
          <DownloadPdf pages={pages} />
          <SocialMedia />
        </div>
      </div>
      {showAds && (
        <div className="ads-container">
          <GoogleAds />
        </div>
      )}
    </div>
  );
}

export default MyBook;
